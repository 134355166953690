import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "../views/Main.view.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Main",
        component: MainView,
    },
    {
        path: "/avresa",
        name: "Avresa",
        component: () => import(/* webpackChunkName: "avresa" */ "@/views/Avresa.view.vue"),
    },
    {
        path: "/junias",
        name: "Flags",
        component: () => import(/* webpackChunkName: "flags" */ "@/views/Flags.view.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
