
import { defineComponent } from "vue";

import MarineFooter from "@/fragments/Footer.fragment.vue";
import MarineNav from "@/fragments/Nav.fragment.vue";

export default defineComponent({
    components: {
        MarineFooter,
        MarineNav,
    },
    setup() {},
});
