
import { defineComponent } from "vue";

export default defineComponent({
    name: "MainView",

    setup() {

    },
});
