import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import mdiVue from "mdi-vue/v3";
import * as mdijs from "@mdi/js";

import MarineLateral from "@/components/Lateral.component.vue";

const app = createApp(App)
    .use(store)
    .use(router)
    .use(mdiVue, {
        icons: mdijs,
    });

app.component("MarineLateral", MarineLateral);

app.mount("#app");
